@media (max-width: 1000px) {
  .detailSection .detailRow {
    display: block !important;
  }
  .detailSection .detailRow .detailCol {
    padding-left: 0 !important;
    margin-left: 0 !important;
    border: none !important;
  }
  .tileDataRow.d-flex.justify-content-between {
    display: block !important;
  }
  .tileDataCol {
    margin-bottom: 2px !important;
  }
  .mainBox {
    display: block !important;
  }
  .rightBox.w-25 {
    width: 100% !important;
  }
  .detailBox {
    width: 100% !important;
    padding-left: 0 !important;
  }
}
@media (max-width: 768px) {
  .userListingData {
    min-width: 90%;
  }
  .k-dialog-wrapper.dialog-sm .k-dialog {
    width: 92%;
  }
}
@media (max-width: 700px) {
  // css start
  .settingData .k-tabstrip {
    display: block;
    .k-vbox,
    .k-vstack {
      display: block;
      flex-flow: wrap;
      .k-tabstrip-items {
        flex-direction: initial;
        .k-item {
          color: rgba(0, 0, 0, 0.54);
          margin: 0 6px 8px 0 !important;
        }
      }
    }
    .k-content {
      padding: 10px 0 5px 2px;
    }
    .spkTrk {
      display: block;
    }
  }
  .track .legends {
    display: block !important;
    ul {
      display: block !important;
      float: left;
      width: 100%;
      padding: 0 0 10px 0;
      li {
        margin: 0;
        padding: 0;
      }
    }
  }
  .listInr-detail {
    display: block !important;
  }
  .table-mobile thead {
    display: none;
  }
  .table-mobile td {
    display: none !important;
  }
  .cardEffect .card-header {
    display: block !important;
  }
  .cardEffect .card-header .buttons-container {
    padding: 10px 0 4px 0 !important;
  }
  .mobile-contain {
    padding: 15px 0 0 0 !important;
  }
  .listMobile {
    width: 100vw !important;
  }
  .table-mobile .listMobile {
    display: block !important;
    width: 100vw !important;
    padding-right: 40px;
    position: relative;
  }
  .table-mobile .k-detail-cell {
    display: block !important;
    width: 90vw !important;
  }
  .table-mobile .k-grid-norecords td {
    display: block !important;
    width: 100vw !important;
  }
  .listRowMobile {
    display: block !important;
  }
  .iconMobileDetail {
    position: absolute;
    top: 2px;
    right: 40px;
  }
  .deviceContent {
    padding: 20px 0 0 0 !important;
  }
  .k-custom-tab .k-tabstrip-items-wrapper {
    padding: 0;
    border: none;
  }
  // css end
}
@media (max-width: 500px) {
  // css start
  .langText {
    display: none;
  }
  .formGroup {
    display: block !important;
  }
  .formGroup .k-form {
    min-width: 100%;
  }
  .filterBtn {
    width: 98%;
  }
  .or-divider span:before {
    width: 66%;
  }
  .or-divider span::after {
    width: 66%;
  }
  .cardEffect .card-header .buttons-container {
    display: block !important;
  }
  .cardEffect .card-header .buttons-container .searchBox input {
    width: 100% !important;
  }
  .gridAction {
    display: block !important;
  }
  .actionLabel {
    padding-bottom: 15px;
  }
  .listMobile .k-rating-container .k-rating-item {
    padding: 0 3px 0 0;
  }
  .listMobile .k-rating-container .k-rating-item .k-icon {
    font-size: 20px;
  }
  .table-mobile .k-pager-numbers-wrap {
    display: none;
  }
  .cardMobile .card-header .k-button-text .btnSpan {
    display: none;
  }
  .cardMobile .card-header .buttons-container {
    position: relative;
  }
  .cardMobile .card-header .searchBox {
    margin-right: 0 !important;
  }
  .cardMobile .card-header .buttons-container .k-button {
    padding: 0;
    width: 27px;
    position: absolute;
    top: -23px;
    right: 0;
    height: 27px !important;
  }
  .formAdd {
    width: 100% !important;
  }
  .profileSrch {
    width: 100%;
    margin-top: 5px !important;
  }
  .addGroupMultiProfile {
    display: block !important;
  }
  .authProfileRow {
    display: block !important;
  }

  // css end
}
@media (max-width: 370px) {
  .playerBtnGroup .PlayerButton {
    height: 28px;
    min-width: 28px;
  }
}
@media (max-width: 359px) {
  // css start
  .cardEffect .card-header {
    display: block !important;
  }
  .formLabel {
    flex-direction: column-reverse;
  }
  a.forgotPassword.btn-link.fs-14 {
    float: left;
    width: 100%;
  }
  .or-divider span:before {
    width: 36%;
  }
  .or-divider span::after {
    width: 36%;
  }
  .k-custom-tab
    .k-tabstrip
    > .k-tabstrip-items-wrapper
    > .k-tabstrip-items
    .k-item {
    width: 100%;
  }
  .k-custom-tab
    .k-tabstrip
    > .k-tabstrip-items-wrapper
    > .k-tabstrip-items
    .k-item
    .k-link {
    max-width: 100% !important;
  }
  // css end
}
